import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FiPhone, FiMail } from 'react-icons/fi';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo-container">
          <img
            src="https://globuscargo.com/wp-content/uploads/2020/08/LOGO-GLOBUS_CARGO-e1597780560604.png"
            alt="Company Logo"
            className="logo"
          />
        </div>
      </div>
      <div className="header-right">
        <div className="header-top">
          <div className="contact-info">
            <span className="contact-item">
              <FiPhone style={{ marginRight: '8px' }} /> +1 786 474 2836
            </span>
            <span className="contact-item" style={{ marginLeft: '20px' }}>
              <FiMail style={{ marginRight: '8px' }} /> info@yourcompany.com
            </span>
          </div>
        </div>
        <div className="header-bottom">
          <div className="hamburger-menu" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
          </div>
          <nav className={`nav-menu ${menuOpen ? 'show' : ''}`}>
            <div className="close-icon" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <a href="#casillero" className="nav-link">Regístrese</a>
            <a href="#casillero" className="nav-link">Casillero</a>
            <a href="#rastreo" className="nav-link">Rastreo</a>
            <a href="#pagos" className="nav-link">Zona de Pagos</a>
            <a href="#cotizar1" className="nav-link">Cotizar</a>
            <a href="#contacto" className="nav-link">Contáctenos</a>
            <a href="#contacto" className="nav-link">Ingreso agencia</a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
