import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./imagenes/gib logo.jpeg"


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <img
            src="https://globuscargo.com/wp-content/uploads/2020/08/LOGO-GLOBUS_CARGO-e1597780560604.png" // Add your logo path here
            alt="Globus Cargo Logo"
            className="footer-logo"
          />
          <p>
            Globus Cargo, soluciones logísticas a la medida de sus necesidades. 
            <br />
            "tu conexión al mundo"
          </p>
        </div>

        <div className="footer-options">
          <h3>Opciones</h3>
          <ul>
  <li> Regístrese</li>
  <li> Casillero</li>
  <li> Rastreo</li>
  <li> Zona de Pagos</li>
  <li> Cotizar</li>
  <li> <a href="/contact-us" >Contáctenos</a></li>
  <li> Ingreso agencia</li>
</ul>

        </div>

        <div className="footer-links">
          <h3>Accesos Directos</h3>
          
          <ul>
  <li><Link to="">Cotizar</Link></li>
  <li><Link to="">Política de tratamiento de datos</Link></li>
  <li><Link to="">Preguntas frecuentes</Link></li>
</ul>
        
        </div>

        <div className="footer-contact">
          <h3>Contacto</h3>
          <p>2120 NW 96 AVENUE, Miami FL, 33172</p>
          <p>+1 786 474 2836</p>
          <p><a href="mailto:info@globuscargo.com">info@globuscargo.com</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright 2023 GlobusCargo todos los derechos reservados.</p>
     
      </div>
    </footer>
  );
};

export default Footer;
